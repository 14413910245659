import * as React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

import { getBlokURL, isExternalURL } from '../../utils'

const Button = ({ blok }) => {
  const classes = classNames(
    'btn btn-primary',
    { 'btn-lg': blok.size === 'lg' },
    { 'mr-auto': blok.align === 'left' },
    { 'mx-auto': blok.align === 'center' },
    { 'ml-auto': blok.align === 'right' }
  )

  if (!isExternalURL(blok)) {
    return (
      <Link className={classes} to={`/${blok.link.cached_url}`}>
        {blok.title}
      </Link>
    )
  }

  return (
    <a className={classes} href={getBlokURL(blok)} target="_blank" rel="noreferrer">
      {blok.title}
    </a>
  )
}

export default Button
