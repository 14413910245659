import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import DynamicComponent from '../dynamicComponent'

const Address = () => {
  const data = useStaticQuery(graphql`
    query AddressQuery {
      storyblokEntry(slug: {eq: "address"}) {
        content
      }
    }
  `)

  const parsedContent = JSON.parse(data.storyblokEntry.content)

  return (
    <div className="footer-address mr-2">
      {parsedContent.blocks.map(blok => <DynamicComponent blok={blok} key={blok._uid} />)}
    </div>
  )
}

export default Address
