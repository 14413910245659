import * as React from 'react'
import { Image as BsImage } from 'react-bootstrap'
import SbEditable from 'storyblok-react'

const Image = ({ blok }) => {
  return (
    <div className="image">
      <SbEditable content={blok}>
        <BsImage fluid src={blok.image.filename} alt={blok.image.alt} />
      </SbEditable>
    </div>
  )
}

export default Image
