import * as React from 'react'
import Accordion from 'react-bootstrap/Accordion'

import DynamicComponent from '../dynamicComponent'

const AccordionContainer = ({ blok }) => {
  const [activeId, setActiveId] = React.useState('')

  return (
    <Accordion onSelect={id => setActiveId(id)}>
      {blok.content.map(element => {
        element.isActive = element._uid === activeId

        return <DynamicComponent key={element._uid} blok={element} />
      })}
    </Accordion>
  )
}

export default AccordionContainer
