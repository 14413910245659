import React from 'react'
import PropTypes from 'prop-types'
import Container from 'react-bootstrap/Container'

import Header from '../layout/header'
import Footer from '../layout/footer'
import './layout.scss'

let OneTrust;
let _etracker;

const Layout = ({ children }) => {
  React.useEffect(() => {
    window.OptanonWrapper = function () { }

    function etrackerSetCookieConsent() {
      var groups = window.OnetrustActiveGroups.split(',');

      if (groups.includes('2'))
       window._etracker.enableCookies(document.location.host);
      else
        window._etracker.disableCookies(document.location.host);
    }

    if ( window.OneTrust ) {
      window.OneTrust.OnConsentChanged(etrackerSetCookieConsent);
    }
  }, [])

  return (
    <>
      <header className="site-header">
        <Container>
          <Header />
        </Container>
      </header>

      <main className="site-content">
        <Container>
          {children}
        </Container>
      </main>

      <footer className="site-footer">
        <Container>
          <Footer />

          <div className="copyright">© {new Date().getFullYear()} Carl Hanser Verlag GmbH & Co. KG</div>
        </Container>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
