import * as React from 'react'
import detectie from 'detectie'
import { Helmet } from 'react-helmet'

const Jobs = () => {
  return (
    <>
      <Helmet>
        <link href="https://cs-assets.b-ite.com/carl-hanser/jobs-api/main-listing.min.css" rel="stylesheet" type="text/css" />

        {typeof window !== "undefined"
          ? (detectie() === 9 && <script src="https://static.b-ite.com/jobs-api/004/api-v4-polyfills-ie9.js"></script>)
          : ''
        }

        <script src="https://static.b-ite.com/jobs-api/004/api-v4.js" data-bite-jobs-api-v4-load="__BiteJobsApiV4Loaded" async defer></script>
        <script src="https://cs-assets.b-ite.com/carl-hanser/jobs-api/main-listing.min.js" async defer></script>
      </Helmet>

      <div id="job-postings"></div>
    </>
  )
}

export default Jobs
