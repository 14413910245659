import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'

import { isExternalURL } from '../../utils'

const FooterMenu = () => {
  const data = useStaticQuery(graphql`
    query FooterMenuQuery {
      storyblokEntry(slug: {eq: "footer-menu"}) {
        content
      }
    }
  `)

  const parsedContent = JSON.parse(data.storyblokEntry.content)
  const mappedContent = new Array(Math.ceil(parsedContent.blocks.length / 4))
    .fill()
    .map(_ => parsedContent.blocks.splice(0, 4))

  return (
    <nav className="footer-menu">
      <Row>
        {mappedContent.map((arr, index) => {
          return (
            <Col key={index}>
              {arr.map(item => {
                if (!isExternalURL(item)) {
                  return (
                    <Link key={item._uid} to={`/${item.link.cached_url}`}>{item.title}</Link>
                  )
                }

                return <a key={item._uid} href={item.link.url}>{item.title}</a>
              })}
            </Col>
          )
        })}
      </Row>
    </nav>
  )
}

export default FooterMenu
