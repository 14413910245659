import * as React from 'react'

import DynamicComponent from '../dynamicComponent'

const Group = ({ blok }) => (
  <div className="group">
    {blok.content.map(content => (
      <DynamicComponent key={content._uid} blok={content} />
    ))}
  </div>
)

export default Group
