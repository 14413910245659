import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Address from './address'
import FooterMenu from './footer-menu'
import SocialMedia from './social-media'

const Footer = () => {
  return (
    <Row>
      <Col lg={9} className="d-lg-flex justify-content-lg-between">
        <Address />
        <FooterMenu />
      </Col>
      <Col lg={3}>
        <SocialMedia />
      </Col>
    </Row>
  )
}

export default Footer
