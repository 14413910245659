import React, { useEffect } from 'react'
import ListGroup from 'react-bootstrap/ListGroup'

const LinkList = ({ uid, list }) => {
  useEffect(() => {
    const links = document.querySelectorAll(`.list-group[data-uid="${uid}"] a.list-group-item`)
    const underline = document.querySelector(`.list-group[data-uid="${uid}"] .link-underline`)

    const handleLinkMouseEnter = (event) => {
      links.forEach(link => {
        if (event.target !== link) {
          link.classList.add('text-muted')
        }
      })

      const posX = event.target.offsetLeft
      const posY = event.target.offsetTop

      underline.style['opacity'] = 1
      underline.style['transform'] = `translate(${posX - 21}px, ${posY + 7}px)`
    }

    const handleLinkMouseLeave = () => {
      links.forEach(link => link.classList.remove('text-muted'))
      underline.style['opacity'] = 0
    }

    links.forEach(link => link.addEventListener('mouseenter', event => handleLinkMouseEnter(event)))
    links.forEach(link => link.addEventListener('mouseleave', () => handleLinkMouseLeave()))

    return () => {
      links.forEach(link => link.removeEventListener('mouseenter', event => handleLinkMouseEnter(event)))
      links.forEach(link => link.removeEventListener('mouseleave', () => handleLinkMouseLeave()))
    }
  }, [uid])

  return (
    <ListGroup variant="flush" data-uid={uid}>
      {list.map(link => <ListGroup.Item key={link._uid} as="a" href={link.link.url}>{link.title}</ListGroup.Item>)}

      <div className="link-underline" />
    </ListGroup>
  )
}

export default LinkList
