import * as React from 'react'
import classNames from 'classnames'
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'

const Text = ({ blok }) => {
  const classes = classNames(
    'text',
    { 'has-background-color': blok.background_color },
    { 'text-small-font': blok.size === 'small' },
    { 'text-normal-font': blok.size === 'normal' },
    { 'text-large-font': blok.size === 'large' },
  )

  return (
    <ReactMarkdown className={classes} rehypePlugins={[rehypeRaw]} children={blok.content.toString()} />
  )
}

export default Text
