import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const Logo = () => {
  const data = useStaticQuery(graphql`
    query LogoQuery {
      allStoryblokEntry(filter: {slug: {eq: "logo"}}) {
        edges {
          node {
            id
            name
            slug
            content
          }
        }
      }
    }
  `)

  const parsedComponent = JSON.parse(data.allStoryblokEntry.edges[0]?.node.content)?.blocks[0]

  return <img src={parsedComponent.image.filename} alt={parsedComponent.image.alt} />
}

export default Logo
