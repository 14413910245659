import * as React from 'react'
import { render } from 'storyblok-rich-text-react-renderer';

const IconBox = ({ blok }) => {
  return (
    <div className="icon-box">
      <div className="icon-box-icon">
        {blok.icon.filename && (
          <img className="icon-box-icon-img" src={blok.icon.filename} alt="" />
        )}
      </div>

      <h3 className="icon-box-title">{blok.title}</h3>
      {blok.text && (
        <div className="icon-box-text">
          {render(blok.text)}
        </div>
      )}
    </div>
  )
}

export default IconBox
