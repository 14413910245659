import * as React from 'react'
import classNames from 'classnames'
import Card from 'react-bootstrap/Card'

import LinkList from '../shared/link-list'

const Category = ({ blok }) => {
  const { _uid, isCompact, color, icon, title, subtitle, linkList } = blok

  const classes = classNames(
    'category',
    'text-center',
    { 'card--compact': isCompact },
  )

  return (
    <Card className={classes} style={{ backgroundColor: color.color }}>
      <Card.Header>
        {icon && (
          <div className="card-icon">
            <img className="card-icon-img" src={icon.filename} alt="" />
          </div>
        )}
        <Card.Title className="text-uppercase" as={isCompact ? 'h3' : 'h2'}>{title}</Card.Title>
        {subtitle && <Card.Subtitle>{subtitle}</Card.Subtitle>}
      </Card.Header>

      <Card.Body>
        {linkList && <LinkList uid={_uid} list={linkList} />}
      </Card.Body>
    </Card>
  )
}

export default Category
