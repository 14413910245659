import * as React from 'react'
import classNames from 'classnames'
import Row from 'react-bootstrap/Row'

import DynamicComponent from '../dynamicComponent'

const GridContainer = ({ blok }) => {
  const classes = classNames(
    { 'equal-heights': blok.equal_boxes },
  )

  return (
    <Row className={classes}>
      {blok.content.map(element => (
        <DynamicComponent key={element._uid} blok={element} />
      ))}
    </Row>
  )
}

export default GridContainer
