import * as React from 'react'
import { Link } from 'gatsby'

import { getBlokURL, isExternalURL } from '../../utils'

const LinkBox = ({ blok }) => {
  if (!isExternalURL(blok)) {
    return (
      <Link className="link-box" to={`/${blok.link.cached_url}`} style={{ backgroundColor: blok.background_color.color }}>
        <div className="link-box-title">{blok.title}</div>
      </Link>
    )
  }

  return (
    <a className="link-box" target="_blank" rel="noreferrer" href={getBlokURL(blok)} style={{ backgroundColor: blok.background_color.color }}>
      <div className="link-box-title">{blok.title}</div>
    </a>
  )
}

export default LinkBox
