import React from 'react'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, graphql, useStaticQuery } from 'gatsby'

import { getBlokURL, isActiveURL, isExternalURL } from '../../utils'

const MainMenu = () => {
  const data = useStaticQuery(graphql`
    query MainMenuQuery {
      storyblokEntry(slug: {eq: "main-menu"}) {
        content
      }
    }
  `)

  const parsedContent = JSON.parse(data.storyblokEntry.content)

  function renderDropdown(blok) {
    return (
      <NavDropdown id="nav-dropdown" key={blok._uid} title={blok.title} renderMenuOnMount={true}>
        {blok.children.map(child => {
          return (
            <NavDropdown.Item key={child._uid} eventKey={child._uid} href={child.link.cached_url}>
              {child.title}
            </NavDropdown.Item>
          )
        })}
      </NavDropdown>
    )
  }

  function renderLink(blok) {
    if (!isExternalURL(blok)) {
      return (
        <Link
          key={blok._uid}
          className="nav-link"
          activeClassName="nav-link active"
          to={`/${blok.link.cached_url}/`}
        >
          {blok.title}
        </Link>
      )
    } else {
      return (
        <Nav.Link
          key={blok._uid}
          href={getBlokURL(blok)}
          active={isActiveURL(blok)}
          target="_blank"
          rel="noreferrer"
        >
          {blok.title}
        </Nav.Link>
      )
    }
  }

  return (
    <Nav className="mr-auto">
      {parsedContent.blocks.map(blok => {
        if (blok.children && blok.children.length > 0) {
          return renderDropdown(blok)
        } else {
          return renderLink(blok)
        }
      })}
    </Nav>
  )
}

export default MainMenu
