export const getBlokURL = (blok) => {
  return blok.link.linktype === 'story' ? `/${blok.link.cached_url}` : blok.link.url
}

export const isActiveURL = (blok) => {
  if (blok.link.linktype !== 'story') {
    return false
  }

  return `/${blok.link.cached_url}` === window.location.pathname
}

export const isExternalURL = (blok) => {
  return blok.link.linktype !== 'story'
}
