import * as React from 'react'
import Col from 'react-bootstrap/Col'

import DynamicComponent from '../dynamicComponent'

const GridItem = ({ blok }) => {
  return (
    <Col
      sm={blok.width_sm}
      md={blok.width_md}
      lg={blok.width_lg}
      xl={blok.width_xl}
    >
      {blok.content.map(element => (
        <DynamicComponent key={element._uid} blok={element} />
      ))}
    </Col>
  )
}

export default GridItem
