import React from 'react'
import SbEditable from 'storyblok-react'

import Text from './bloks/text'
import Jobs from './bloks/jobs'
import Group from './bloks/group'
import Image from './bloks/image'
import Spacer from './bloks/spacer'
import Button from './bloks/button'
import IconBox from './bloks/icon-box'
import LinkBox from './bloks/link-box'
import Category from './bloks/category'
import Headline from './bloks/headline'
import GridItem from './bloks/grid-item'
import AccordionItem from './bloks/accordion-item'
import GridContainer from './bloks/grid-container'
import HorizontalLine from './bloks/horizontal-line'
import SocialMediaLink from './bloks/social-media-link'
import AccordionContainer from './bloks/accordion-container'

const Components = {
  'text': Text,
  'jobs': Jobs,
  'group': Group,
  'image': Image,
  'button': Button,
  'spacer': Spacer,
  'icon-box': IconBox,
  'link-box': LinkBox,
  'category': Category,
  'headline': Headline,
  'grid-item': GridItem,
  'accordion-item': AccordionItem,
  'grid-container': GridContainer,
  'horizontal-line': HorizontalLine,
  'social-media-link': SocialMediaLink,
  'accordion-container': AccordionContainer,
}

const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return (<SbEditable content={blok}><Component blok={blok} /></SbEditable>)
  }
  return (<p>The component <strong>{blok.component}</strong> has not been created yet.</p>)
}

export default DynamicComponent
