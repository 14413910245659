import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import DynamicComponent from '../dynamicComponent'

const SocialMedia = () => {
  const data = useStaticQuery(graphql`
    query SocialMediaQuery {
      storyblokEntry(slug: {eq: "social-media"}) {
        content
      }
    }
  `)

  const parsedContent = JSON.parse(data.storyblokEntry.content)

  return (
    <div className="footer-social-media">
      {parsedContent.blocks.map(blok => <DynamicComponent blok={blok} key={blok._uid} />)}
    </div>
  )
}

export default SocialMedia
