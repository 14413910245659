import React, { Fragment } from 'react'
import Navbar from 'react-bootstrap/Navbar'

import Logo from './logo'
import MainMenu from './main-menu'

const Header = () => {
  return (
    <Navbar expand="lg">
      <Navbar.Brand href="/">
        <Logo />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="main-menu" children={(
        <Fragment>
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </Fragment>
      )} />
      <Navbar.Collapse id="main-menu">
        <MainMenu />
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header
