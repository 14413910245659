import * as React from 'react'

import { isExternalURL } from '../../utils'

const SocialMediaLink = ({ blok }) => {
  return (
    <a className="social-media-link" target={isExternalURL(blok) && '_blank'} href={blok.link.url}>
      <img src={blok.icon.filename} alt={blok.icon.alt} />
    </a>
  )
}

export default SocialMediaLink
